import styled from 'styled-components';

import {color, zindex} from '../../../../styles/variables';
import {md, sm, xl} from '../../../../styles/media';

export const StyledBanner = styled.div`
    background-color: ${color.red};
    padding: 20px;
    border-radius: 20px;
    transform: translateY(50%) translateX(0%) rotateZ(-5deg);
    width: 500px;
    z-index: ${zindex.burger};
    text-align: center;
    bottom: 0px;
    right: 0px;
    margin: 0px auto;
    min-height: 220px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    position: relative;
    &:after {
        content: '';
        position: absolute;
        top: 10px;
        right: 10px;
        bottom: 10px;
        left: 10px;
        border: 3px dashed ${color.white};
        border-radius: 20px;
    }
    span {
        position: relative;
        z-index: 1;
    }
    p,
    h4,
    h5 {
        color: ${color.white};
    }
    ${sm`
        margin-bottom: 70px;
    `}
    ${md`
        margin-bottom: 70px;
        right: -100px;
    `}
    ${xl`
        position: absolute;
        transform: translateY(70%) translateX(-30%) rotateZ(-5deg);
        bottom: 0px;
        margin-bottom: 0px;
    `}
`;
