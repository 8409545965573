import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import isEqual from 'lodash.isequal';

import Form, {Input} from '../../../components/Form';
import Button from '../../../components/Button';
import {
    passwordValidator,
    passwordConfirmationValidator,
    emailValidator,
    usernameValidatorStrict,
} from '../../../../utils/form-validation';
import {
    selectAccessData,
    updateUserProfile,
    selectRoles as selectUserRoles,
    Roles,
} from '../../../../model/profile';
import {Column, Container, Row} from '../../../layout/Grid';
import formLang from '../../../../lang/forms.lang';
import profileLang from '../../../../lang/pages/profil.lang';
import {sectionThemeNames} from '../../../layout/Grid/Section.styles';
import Headline from '../../../components/Headline';

const validationSchema = yup.object().shape({
    username: usernameValidatorStrict,
    email: emailValidator,
    password: passwordValidator(false),
    passwordConfirmation: passwordConfirmationValidator,
});

export const AccessDataForm = ({
    onSubmit,
    username,
    email,
    theme,
    userRoles,
}) => {
    const isSalesRepresentative = userRoles.includes(
        Roles.SALES_REPRESENTATIVE
    );
    const defaultValues = {
        username,
        email,
        password: '',
        passwordConfirmation: '',
    };

    const {control, handleSubmit, errors, reset, watch} = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues,
    });

    useEffect(() => {
        reset(defaultValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email, username]);

    const allFields = watch();
    return (
        <Form
            theme={theme}
            onSubmit={handleSubmit((data) => {
                onSubmit(data);
                reset(defaultValues);
            })}>
            <Container width="narrow">
                <Row>
                    <Column xs={12}>
                        <Headline type="h4" hideTopMargin hideBottomMargin>
                            {profileLang.accessDataForm.headline}
                        </Headline>
                    </Column>
                    <Column md={6}>
                        <Input
                            name={formLang.username.name}
                            placeholder={formLang.username.placeholder}
                            label={formLang.username.label}
                            theme={theme}
                            control={control}
                            errors={errors}
                            isDisabled={isSalesRepresentative}
                        />
                    </Column>
                    <Column md={6}>
                        <Input
                            name={formLang.email.name}
                            placeholder={formLang.email.placeholder}
                            label={formLang.email.label}
                            theme={theme}
                            control={control}
                            errors={errors}
                            isDisabled={isSalesRepresentative}
                        />
                    </Column>
                    <Column md={6}>
                        <Input
                            name={formLang.choosePassword.name}
                            placeholder={formLang.choosePassword.placeholder}
                            label={formLang.choosePassword.label}
                            theme={theme}
                            type="password"
                            control={control}
                            errors={errors}
                        />
                    </Column>
                    <Column md={6}>
                        <Input
                            name={formLang.passwordConfirm.name}
                            placeholder={formLang.passwordConfirm.placeholder}
                            label={formLang.passwordConfirm.label}
                            theme={theme}
                            control={control}
                            type="password"
                            errors={errors}
                        />
                    </Column>
                    <Column xs={12} className="ap-padding--top">
                        <Button
                            disabled={isEqual(allFields, defaultValues)}
                            type="submit"
                            label={profileLang.submitButton}
                            className="ap-button ap-button--left"
                        />
                    </Column>
                </Row>
            </Container>
        </Form>
    );
};

AccessDataForm.propTypes = {
    onSubmit: PropTypes.func,
    username: PropTypes.string,
    email: PropTypes.string,
    theme: PropTypes.oneOf(sectionThemeNames),
    userRoles: PropTypes.arrayOf(PropTypes.string),
};

AccessDataForm.defaultProps = {
    onSubmit: () => {},
    email: '',
    username: '',
    theme: 'default',
    userRoles: [],
};

const ConnectedAccessDataForm = (props) => {
    const dispatch = useDispatch();
    const accessData = useSelector(selectAccessData);
    const userRoles = useSelector(selectUserRoles);
    const handleSubmit = (data) => {
        dispatch(updateUserProfile(data));
    };
    return (
        <AccessDataForm
            {...accessData}
            {...props}
            userRoles={userRoles}
            onSubmit={handleSubmit}
        />
    );
};

export default ConnectedAccessDataForm;
