import styled, {css} from 'styled-components';

import {headerHeight} from '../../../styles/variables';
import {contentMaxWidthsMedium} from '../../../styles/layout-utils';
import {xl, xxl, xlMax} from '../../../styles/media';

const fullHeaderStyles = css`
    ${xlMax`
        position: absolute;
    `};
`;

export const StyledHeader = styled.header`
    ${contentMaxWidthsMedium};
    position: relative;
    margin: 1.25rem auto;
    display: flex;
    flex-direction: column;
    padding-top: ${(props) => (props.paddingTop ? '2rem ' : '0')};
    padding-bottom: ${(props) => (props.paddingBottom ? '2rem ' : '0')};
    align-items: ${(props) => (props.fullHeader ? 'flex-start ' : 'center')};
    ${(props) => props.fullHeader && fullHeaderStyles};

    ${xl`
        width: 100%;
        max-width: none;
        margin: 0 auto;
        padding: 0;
        height: ${headerHeight};
        flex-direction: row;
        justify-content: space-between;
    `};
`;

export const Wrapper = styled.div`
    flex: 0 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    padding: ${(props) => (props.fullHeader ? '2rem 0' : '0 1rem')};
    /* IE11 Fix */
    max-width: 100%;

    ${xlMax`
        padding: ${(props) => props.fullHeader && '2rem'};
    `}

    ${xl`
        align-items: flex-start;
        text-align: left;
        ${(props) =>
            props.fullHeader
                ? css`
                      width: auto;
                  `
                : css`
                      width: 50%;
                  `}


        ${(props) =>
            props.photo &&
            css`
                max-width: 480px;
            `}
    `}

    ${xxl`
        ${(props) =>
            props.photo &&
            css`
                max-width: 600px;
            `}
    `}
`;

const ImageWrapperStyles = css`
    height: 100%;
    display: block;
    right: 0;
    position: absolute;
    top: 0;
`;

export const ImgWrapper = styled.div`
    width: 50%;
    ${ImageWrapperStyles};
    ${(props) =>
        props.photo
            ? css`
                  background-image: url(${props.img});
                  background-repeat: no-repeat;
                  background-size: cover;
                  background-position: center;
              `
            : null}
    ${xlMax`
        ${(props) =>
            props.photo
                ? css`
                      display: none;
                  `
                : css`
                      width: 100%;
                      position: relative;
                      margin-top: 1.25rem;
                      text-align: center;
                  `}
    `}
`;

export const FullHeaderImgWrapper = styled.div`
    width: 70%;
    ${ImageWrapperStyles};
    background-image: url(${(props) => props.img});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    ${xlMax`
        width: 100%;
        position: initial;
        text-align: center;
        height: 550px;
    `}
`;

export const Img = styled.img`
    flex: 0 1 auto;
    max-width: 100%;
    ${xl`
        position: absolute;
        bottom: 0;
        padding: 0;
        left: 0;
        margin: auto;
    `}
`;
