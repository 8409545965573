import React from 'react';
import PropTypes from 'prop-types';

import {
    StyledHeader,
    Wrapper,
    Img,
    ImgWrapper,
    FullHeaderImgWrapper,
} from './styles';
import Link from '../../components/Link';
import ResponsiveSwitch from '../../layout/ResponsiveSwitch';
import {Container, Section, Row} from '../Grid';

const Header = ({
    children,
    img,
    photo,
    paddingTop,
    paddingBottom,
    backBtnLink,
    alt,
    theme,
    fullHeader,
    prefix,
}) => {
    const headerContent = (
        <Row style={{position: 'relative'}}>
            <StyledHeader
                paddingTop={paddingTop}
                fullHeader={fullHeader}
                paddingBottom={paddingBottom}>
                <Wrapper photo={photo} fullHeader={fullHeader}>
                    {backBtnLink && (
                        <Link href={backBtnLink} icon="arrowBack" iconLeft>
                            zurück
                        </Link>
                    )}
                    {children}
                </Wrapper>
            </StyledHeader>
            {fullHeader ? (
                <FullHeaderImgWrapper img={img}>
                    {!photo && <Img src={img} alt={alt} />}
                </FullHeaderImgWrapper>
            ) : (
                <ImgWrapper photo={photo} img={img}>
                    {!photo && <Img src={img} alt={alt} />}
                </ImgWrapper>
            )}
            {prefix}
        </Row>
    );
    return (
        <Section theme={theme} noPadding fullHeader={fullHeader}>
            <ResponsiveSwitch>
                {(match) => {
                    if (match.xl) {
                        return (
                            <Container width="wide" noGutter>
                                {headerContent}
                            </Container>
                        );
                    } else {
                        return <Container noGutter>{headerContent}</Container>;
                    }
                }}
            </ResponsiveSwitch>
        </Section>
    );
};

Header.propTypes = {
    children: PropTypes.any,
    prefix: PropTypes.any,
    img: PropTypes.string.isRequired,
    photo: PropTypes.bool,
    paddingTop: PropTypes.bool,
    paddingBottom: PropTypes.bool,
    backBtnLink: PropTypes.string,
    fullHeader: PropTypes.bool,
    alt: PropTypes.string,
    theme: PropTypes.string,
};

Header.defaultProps = {
    theme: 'blue',
    fullHeader: false,
    backButtonLink: '',
    children: null,
    prefix: null,
};

export default Header;
