import React, {useContext, useEffect} from 'react';

import Layout from '../ui/layout/Layout';
import Headline from '../ui/components/Headline';
import Paragraph from '../ui/components/Paragraph';
import Header from '../ui/layout/Header';
import ProfileHeaderImage from '../assets/images/profil/img-hero--deinedaten.png';
import {Container, Section} from '../ui/layout/Grid';
import lang from '../lang/pages/profil.lang';
import PersonalDataForm from '../ui/domains/profile/PersonalDataForm';
import AccessDataForm from '../ui/domains/profile/AccessDataForm';
import PharmacyDataForm from '../ui/domains/pharmacy/PharmacyDataForm';
import PreferredCategoriesForm from '../ui/domains/profile/PreferredCategoriesForm';
import UserTypeForm from '../ui/domains/profile/UserTypeForm';
import LocationContext from '../ui/utils/LocationContext';
import {scrollToAnchorStart} from '../utils/scroll-utils';
import InvitationCodeBanner from '../ui/domains/profile/InvitationCodeBanner';

const Profil = () => {
    const location = useContext(LocationContext);

    useEffect(() => {
        if (location && location.hash === '#personalisierung') {
            scrollToAnchorStart('personalisierung');
        }
    }, [location]);

    return (
        <Layout>
            <Header
                img={ProfileHeaderImage}
                alt={lang.headerAlt}
                prefix={<InvitationCodeBanner />}
                paddingTop
                paddingBottom>
                <Headline type="h1">{lang.deineDatenUndDu}</Headline>
                <Paragraph>{lang.paragraph1}</Paragraph>
            </Header>
            <Section yPadding>
                <PersonalDataForm />
                <AccessDataForm />
                <PharmacyDataForm />
            </Section>
            <Section theme="blue" anchor="personalisierung">
                <Container width="narrow">
                    <Headline type="h4">
                        {lang.trainingTopics.headline}
                    </Headline>
                </Container>
                <PreferredCategoriesForm isStandalone={true} />
                <UserTypeForm isStandalone={true} />
            </Section>
        </Layout>
    );
};

export default Profil;
