import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import isEqual from 'lodash.isequal';

import Form, {Input, Select, Checkbox} from '../../../components/Form';
import Button from '../../../components/Button';
import {
    salutationValidator,
    firstNameValidator,
    lastNameValidator,
} from '../../../../utils/form-validation';
import {
    selectPersonalData,
    updateUserProfile,
    selectRoles as selectUserRoles,
    Roles,
} from '../../../../model/profile';
import formLang from '../../../../lang/forms.lang';
import profileLang from '../../../../lang/pages/profil.lang';
import {sectionThemeNames} from '../../../layout/Grid/Section.styles';
import {Column, Container, Row} from '../../../layout/Grid';
import ResponsiveSwitch from '../../../layout/ResponsiveSwitch';
import Headline from '../../../components/Headline';

const validationSchema = yup.object().shape({
    salutation: salutationValidator,
    firstName: firstNameValidator,
    lastName: lastNameValidator(true),
});

export const PersonalDataForm = ({
    onSubmit,
    salutation,
    firstName,
    lastName,
    theme,
    newsletter,
    userRoles,
}) => {
    const isSalesRepresentative = userRoles.includes(
        Roles.SALES_REPRESENTATIVE
    );
    const isTestUser = userRoles.includes(Roles.TEST_USER);
    const defaultValues = {
        firstName,
        lastName,
        salutation:
            formLang.salutation.options.find(
                (entry) => entry.value === salutation
            ) || null,
        newsletter,
    };

    const {control, handleSubmit, errors, reset, watch} = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues,
    });

    useEffect(() => {
        reset(defaultValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [firstName, salutation, lastName]);

    const transformDataForSubmit = ({
        firstName,
        lastName,
        salutation,
        newsletter,
    }) => {
        onSubmit({
            firstName,
            lastName,
            salutation: salutation.value,
            newsletter,
        });
    };
    const allFields = watch();
    return (
        <Form theme={theme} onSubmit={handleSubmit(transformDataForSubmit)}>
            <Container width="narrow">
                <Row>
                    <Column xs={12}>
                        <Headline type="h4" hideTopMargin hideBottomMargin>
                            {profileLang.personalDataForm.headline}
                        </Headline>
                    </Column>
                    <Column md={6}>
                        <Select
                            name={formLang.salutation.name}
                            label={formLang.salutation.label}
                            options={formLang.salutation.options}
                            control={control}
                            sectionTheme={theme}
                            errors={errors}
                            isDisabled={isSalesRepresentative}
                        />
                    </Column>
                    <ResponsiveSwitch>
                        {(match) => {
                            if (match.md) {
                                return <Column md={6} />;
                            } else {
                                return null;
                            }
                        }}
                    </ResponsiveSwitch>
                    <Column md={6}>
                        <Input
                            name={formLang.firstName.name}
                            placeholder={formLang.firstName.placeholder}
                            label={formLang.firstName.label}
                            theme={theme}
                            control={control}
                            errors={errors}
                            isDisabled={isSalesRepresentative}
                        />
                    </Column>
                    <Column md={6}>
                        <Input
                            name={formLang.lastName.name}
                            placeholder={formLang.lastName.placeholder}
                            label={formLang.lastName.label}
                            theme={theme}
                            control={control}
                            errors={errors}
                            isDisabled={isSalesRepresentative}
                        />
                    </Column>
                    <Column xs={12}>
                        <Checkbox
                            name={formLang.newsletter.name}
                            label={formLang.newsletter.label}
                            control={control}
                            errors={errors}
                            disabled={isSalesRepresentative || isTestUser}
                        />
                    </Column>
                    <Column xs={12}>
                        <Button
                            disabled={
                                isEqual(allFields, defaultValues) ||
                                isSalesRepresentative
                            }
                            type="submit"
                            label={profileLang.submitButton}
                            className="ap-button ap-button--left"
                        />
                    </Column>
                </Row>
            </Container>
        </Form>
    );
};

PersonalDataForm.propTypes = {
    onSubmit: PropTypes.func,
    salutation: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    theme: PropTypes.oneOf(sectionThemeNames),
    newsletter: PropTypes.bool,
    userRoles: PropTypes.arrayOf(PropTypes.string),
};

PersonalDataForm.defaultProps = {
    onSubmit: () => {},
    salutation: '',
    firstName: '',
    lastName: '',
    theme: 'default',
    newsletter: false,
    userRoles: [],
};

const ConnectedPersonalDataForm = (props) => {
    const dispatch = useDispatch();
    const personalData = useSelector(selectPersonalData);
    const userRoles = useSelector(selectUserRoles);
    const handleSubmit = (data) => {
        dispatch(updateUserProfile(data));
    };
    return (
        <PersonalDataForm
            {...personalData}
            {...props}
            userRoles={userRoles}
            onSubmit={handleSubmit}
        />
    );
};

export default ConnectedPersonalDataForm;
