import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {selectPersonalData} from '../../../../model/profile';

import Paragraph from '../../../components/Paragraph';
import profileLang from '../../../../lang/pages/profil.lang';
import {StyledBanner} from './styles';

const InvitationCodeBanner = ({referralCode, referralCodeUsages}) => (
    <StyledBanner>
        {referralCodeUsages >= 3 ? (
            <Paragraph size="small">
                {profileLang.invitationCodeBanner.usedCode}
            </Paragraph>
        ) : (
            <span>
                <Paragraph size="medium">
                    {profileLang.invitationCodeBanner.title}
                </Paragraph>
                <Paragraph size="small">
                    {profileLang.invitationCodeBanner.referallCodeTitle}{' '}
                    {referralCode}
                </Paragraph>
                <Paragraph size="xsmall">
                    {profileLang.invitationCodeBanner.text}
                </Paragraph>
            </span>
        )}
    </StyledBanner>
);

InvitationCodeBanner.propTypes = {
    referralCode: PropTypes.string,
    referralCodeUsages: PropTypes.string,
};

const ConnectedInvitationCodeBanner = () => {
    const personalData = useSelector(selectPersonalData);

    return personalData.referralCode !== undefined ? (
        <InvitationCodeBanner {...personalData} />
    ) : null;
};

export default ConnectedInvitationCodeBanner;
