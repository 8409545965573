import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import isEqual from 'lodash.isequal';

import Form, {Input, Select} from '../../../components/Form';
import Button from '../../../components/Button';

import {
    identificationNumberValidator,
    pharmacyValidator,
} from '../../../../utils/form-validation';
import {
    selectSortedPharmacyListByName,
    selectCurrentPharmacy,
    changePharmacy,
    loadListByZip,
    clearOtherPharmacy,
} from '../../../../model/pharmacy';
import {Column, Container, Row} from '../../../layout/Grid';
import Headline from '../../../components/Headline';
import formLang from '../../../../lang/forms.lang';
import profileLang from '../../../../lang/pages/profil.lang';
import {sectionThemeNames} from '../../../layout/Grid/Section.styles';
import {selectPendingRequests} from '../../../../model/backend-api';
import {LOAD_LIST_BY_ZIP} from '../../../../model/pharmacy/actions';
import {selectRoles as selectUserRoles, Roles} from '../../../../model/profile';

const validationSchema = yup.object().shape({
    identificationNumber: identificationNumberValidator,
    pharmacy: pharmacyValidator(),
});

export const PharmacyDataForm = ({
    onSubmit,
    pharmacyList,
    currentPharmacy,
    fetchPharmaciesByZip,
    theme,
    isLoading,
    clearPharmacy,
    userRoles,
}) => {
    const isSalesRepresentative = userRoles.includes(
        Roles.SALES_REPRESENTATIVE
    );
    const isTestUser = userRoles.includes(Roles.TEST_USER);
    const defaultValues = {
        pharmacy: currentPharmacy
            ? {
                  label: `${currentPharmacy.name}, ${currentPharmacy.zip}`,
                  value: currentPharmacy.id,
                  zip: currentPharmacy.zip,
              }
            : '',
        identificationNumber: '',
    };

    const {control, handleSubmit, errors, reset, watch} = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues,
    });

    useEffect(() => {
        reset(defaultValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pharmacyList, currentPharmacy]);

    const transformDataForSubmit = ({pharmacy, identificationNumber}) => {
        onSubmit({pharmacyId: pharmacy.value, identificationNumber});
    };

    const allFields = watch();
    const pharmacySelectData = pharmacyList.map((pharmacy) => ({
        value: pharmacy.id,
        label: `${pharmacy.name}, ${pharmacy.zip}`,
        zip: pharmacy.zip,
    }));

    const filterOptions = (option, input) => {
        if (input.length < 3) {
            return null;
        }
        return option.data.zip.includes(input);
    };

    const handleClearOtherPharmacy = () => {
        if (currentPharmacy) clearPharmacy(currentPharmacy);
    };

    return (
        <Form theme={theme} onSubmit={handleSubmit(transformDataForSubmit)}>
            <Container width="narrow">
                <Row>
                    <Column xs={12}>
                        <Headline type="h4" hideTopMargin hideBottomMargin>
                            {profileLang.pharmacyDataForm.headline}
                        </Headline>
                    </Column>
                    <Column md={12}>
                        <Select
                            name={formLang.pharmacy.name}
                            label={formLang.pharmacy.label1}
                            placeholder={formLang.zip.placeholder}
                            blurInputOnSelect={true}
                            sectionTheme={theme}
                            isNumber
                            onMenuOpen={handleClearOtherPharmacy}
                            noOptionsMessage={() =>
                                isLoading
                                    ? formLang.pharmacy.loadPharmacies
                                    : formLang.pharmacy.noPharmacies
                            }
                            options={pharmacySelectData}
                            filterOption={filterOptions}
                            clearValueOnFocus={true}
                            control={control}
                            errors={errors}
                            maxLength={5}
                            onInputChange={(input) => {
                                if (input.length === 3 || input.length === 5) {
                                    fetchPharmaciesByZip(input);
                                }
                            }}
                            isDisabled={isSalesRepresentative || isTestUser}
                        />
                    </Column>
                    <Column xs={12}>
                        <Input
                            name={formLang.idNumber.name}
                            placeholder={formLang.idNumber.placeholder}
                            label={formLang.idNumber.label}
                            theme={theme}
                            control={control}
                            errors={errors}
                            type="number"
                            isDisabled={isSalesRepresentative || isTestUser}
                        />
                    </Column>
                    <Column xs={12} className="ap-padding--top">
                        <Button
                            disabled={isEqual(allFields, defaultValues)}
                            type="submit"
                            label={profileLang.submitButton}
                            className="ap-button ap-button--left"
                        />
                    </Column>
                </Row>
            </Container>
        </Form>
    );
};

PharmacyDataForm.propTypes = {
    onSubmit: PropTypes.func,
    clearPharmacy: PropTypes.func,
    pharmacyList: PropTypes.array,
    currentPharmacy: PropTypes.object,
    theme: PropTypes.oneOf(sectionThemeNames),
    fetchPharmaciesByZip: PropTypes.func,
    isLoading: PropTypes.bool,
    userRoles: PropTypes.arrayOf(PropTypes.string),
};

PharmacyDataForm.defaultProps = {
    onSubmit: () => {},
    clearPharmacy: () => {},
    fetchPharmaciesByZip: () => {},
    pharmacyList: [],
    currentPharmacy: null,
    theme: 'default',
    isLoading: false,
    userRoles: [],
};

const ConnectedPharmacyDataForm = (props) => {
    const dispatch = useDispatch();
    const currentPharmacy = useSelector(selectCurrentPharmacy);
    const sortedPharmacyList = useSelector(selectSortedPharmacyListByName);
    const pendingRequests = useSelector(selectPendingRequests);
    const userRoles = useSelector(selectUserRoles);

    const handleSubmit = ({pharmacyId, identificationNumber}) => {
        dispatch(changePharmacy(pharmacyId, identificationNumber));
    };

    const loadPharmaciesByZip = (zip) => {
        dispatch(loadListByZip(zip));
    };

    const clearPharmacy = (pharmacy) => {
        dispatch(clearOtherPharmacy(pharmacy));
    };

    return (
        <PharmacyDataForm
            {...props}
            onSubmit={handleSubmit}
            currentPharmacy={currentPharmacy}
            clearPharmacy={clearPharmacy}
            pharmacyList={sortedPharmacyList}
            fetchPharmaciesByZip={loadPharmaciesByZip}
            isLoading={pendingRequests.some((e) => e.type === LOAD_LIST_BY_ZIP)}
            userRoles={userRoles}
        />
    );
};

export default ConnectedPharmacyDataForm;
